
import "./CardHeader.css"
import {Container, Row, Col } from 'react-bootstrap'

function CardHeader() {

    return (
        <header className="header p-3">
            <img style={{maxWidth: "250px", maxHeight: "25px"}} src="logoeris.png" className="max-width: 250px" alt="dr Irena Eris"></img>
        </header>
        
    )

}

export default CardHeader;